<template>
  <div class="home font-bold font-base text-base lg:text-vw tracking-base">
    <div class="p-2 flex flex-wrap z-30 relative" :style="{'color': site.textColour.hex}">
        <div class="pr-4 uppercase" v-if="site.title" >
          <router-link to="/" :style="{'color': site.linkColour.hex}" href="/">{{site.title}}</router-link>
       </div>
        <div class="pr-4 uppercase" >
          REBEL REBEL PICKUP
       </div>            
    </div>

    <div class="w-full mt-16 p-2" v-if="site.shopText">
        <div v-html="site.shopText.html" class="p-rules img-rules whitespace-pre-line" :style="{'color': site.textColour.hex}"></div>
    </div>
    
    <div id="shopwrapper" :class="{'shake': formData.shake }">
      <div id='collection-component-1630992395454' class="px-2 mt-0 lg:mt-0"></div>
    </div>

    <div class="p-2 mb-20">
      Have you added a pickup time?

      <button type="button" id="subscribe" :class="{'bg-black text-white border-black': formData.subscribe }" class="border-2 p-2 font-bold font-base mr-4 hover:bg-black hover:text-white hover:border-black" @click="formData.subscribe=true">
        Yes
      </button>

      <button type="button" id="subscribe" class="border-2 p-2 font-bold font-base hover:bg-black hover:text-white hover:border-black" @click="[formData.subscribe=false, formData.shake=true]">
        No
      </button>      
    </div>

     <div class="p-2 uppercase text-center my-12" v-if="formData.subscribe">
          <router-link to="/menu" href="/menu" class="border-2 p-2 hover:bg-black hover:text-white hover:border-black">Food Menu ➞</router-link>
    </div>     

    <div class="p-2 uppercase" :style="{'color': site.textColour.hex}">
      Any problems?
      <div class="pr-4 uppercase" v-if="site.phoneNumber" >
        <a :style="{'color': site.linkColour.hex}" class="pr-4" :href="'tel:' + site.phoneNumber" >Hotline</a>
        {{site.phoneNumber}}
      </div>
      <div class="pr-4 w-full overflow-ellipsis overflow-hidden uppercase" v-if="site.email" >
        <a :style="{'color': site.linkColour.hex}" class="pr-4 " :href="'mailto:' + site.email" >Email</a>
        {{site.email}}
      </div>      
    </div>

  </div>

  

</template> 

<script>
import gql from "graphql-tag";
import postscribe from 'postscribe'


export default {
  name: 'Pickup',
  data() {
    return {
      formData: { 
        subscribe: false,
        shake: false
    }, 
    }
  },
  components: {

  },
  methods: {
  checkMounted: function () {
     postscribe(
          "#app",
          /* eslint-disable-next-line */
          `<script type="text/javascript">
          (function () {
  var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
      domain: 'rebel-rebel-dining.myshopify.com',
      storefrontAccessToken: 'b428134372091662e52b95e185030222',
    });
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent('collection', {
        id: '276632928418',
        node: document.getElementById('collection-component-1630992395454'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: {
  "product": {
    "styles": {
      "product": {
        "@media (min-width: 850px)": {
          "max-width": "calc(33.33333% - 8px)",
          "margin-left": "8px",
          "margin-bottom": "50px",
          "width": "calc(33.33333% - 8px)"
        },
        "@media (min-width:600px)": {
          "min-width": "100%",
        },
        "@media (min-width:900px)": {
          "min-width": "auto !important",
        },
        "min-width": "100%",    
        "margin-bottom": "50px",    
        "text-align": "center",
        "padding": "40px 20px 40px 20px",
        "background": "black",
        "color": "white",
        "img": {
          "height": "calc(100% - 15px)",
          "position": "absolute",
          "left": "0",
          "right": "0",
          "top": "0",
          "object-fit": "cover",
          "width": "100%"
        },
        "imgWrapper": {
          "padding-top": "calc(75% + 15px)",
          "position": "relative",
          "height": "0"
        }
      },
      "title": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "bold",
        "font-size": "18px",
        "color": "white"
      },
      "button": {
        ":hover": {
          "background-color": "blue",
          "color": "white",
        },
        ":focus": {
          "background-color": "blue",
          "color": "white",
        },
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "bold",
        "font-size": "18px",
        "color": "#000000",
        "background-color": "white",
        "border-radius": "0px",
        "border": "2px solid #000000 !important"
      },
      "prices": {
        "margin-bottom": "0px",
        "display": "none"
      },
      "quantityInput": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "bold",
        "font-size": "18px",
        "color": "#000000",
        "border": "2px solid black",
        "border-radius": "0px",
        "display": "none"
      },
      "price": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "bold",
        "font-size": "18px",
        "color": "#000000"
      },
      "compareAt": {
        "color": "#000000"
      },
      "unitPrice": {
        "color": "#000000"
      }
    },
    "order": [
      'img',
      'imgWithCarousel',
      'title',
      'variantTitle',
      'price',
      'description',
      'options',
      'buttonWithQuantity',
      'button',
    ],
    "contents": {
      "button": false,
      "buttonWithQuantity": true,
      "description": true
    },
    "description": {
      "color": "red !important",
    },
    "text": {
      "button": "Confirm",
      "outOfStock": "N/A",
      "unavailable": "N/A",
      "noteDescription": "Customize the note label!"
    }
  },
  "productSet": {
    "styles": {
      "products": {
        "@media (min-width: 850px)": {
          "margin-left": "-8px"
        },
        "display": "flex",
        "flex-wrap": "wrap",
        "font-size": "18px !important",
        "color": "black !important",
        "font-family": "courier, mono",
        "justify-content": "center"
      }
    }
  },
  "modalProduct": {
    "contents": {
      "img": false,
      "imgWithCarousel": true,
      "button": false,
      "buttonWithQuantity": true
    },
    "styles": {
      "product": {
        "@media (min-width: 850px)": {
          "max-width": "100%",
          "margin-left": "0px",
          "margin-bottom": "0px"
        }
      },
      "button": {
        ":hover": {
          "background-color": "#004ce6",
          "transition": "0s"
        },
        "background-color": "#0054ff",
        ":focus": {
          "background-color": "#004ce6"
        },
        "border-radius": "0px"
      },
      "title": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "bold",
        "font-size": "18px",
        "color": "#000000"
      },
      "description": {
        "color": "red !important",
      },
      "price": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "normal",
        "font-size": "18px",
        "color": "#4c4c4c"
      },
      "compareAt": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "normal",
        "font-size": "15.299999999999999px",
        "color": "#4c4c4c"
      },
      "unitPrice": {
        "font-family": "Helvetica Neue, sans-serif",
        "font-weight": "normal",
        "font-size": "15.299999999999999px",
        "color": "#4c4c4c"
      }
    },
    "text": {
      "button": "Add to cart"
    }
  },
  "option": {
    "styles": {
      "select": {
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important",
        "border": "2px solid black !important",
        "border-radius": "0px"
      },
      "selectIcon": {
        "fill": "black"
      },
      "description": {
        "color": "red !important",
      },      
      "label": {
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important"
      },      
    },
  },
  "cart": {
    "styles": {
      "cart": {
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important"
      },
      "button": {
        ":hover": {
          "background-color": "#004ce6"
        },
        "background-color": "#0054ff",
        ":focus": {
          "background-color": "#004ce6"
        },
        "border-radius": "0px"
      },
      "title": {
        "color": "#000000",
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important"        
      },
      "header": {
        "color": "#000000"
      },
      "lineItems": {
        "color": "#000000"
      },
      "subtotalText": {
        "color": "#000000",
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important"
      },
      "subtotal": {
        "color": "#000000",
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important"
      },
      "notice": {
        "color": "#000000",
        "display": "none",
      },
      "currency": {
        "color": "#000000"
      },
      "close": {
        "color": "#000000",
        ":hover": {
          "color": "#000000"
        }
      },
      "empty": {
        "color": "#000000"
      },
      "noteDescription": {
        "color": "#000000",
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important"
      },
      "noteTextArea": {
        "color": "#000000",
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important"
      }, 
      "total": {
        "color": "#000000",
        "font-family": "Helvetica Neue, sans-serif !important",
        "font-weight": "bold !important",
        "font-size": "18px !important",
        "color": "#000000 !important"
      },            
      "discountText": {
        "color": "#000000"
      },
      "discountIcon": {
        "fill": "#000000"
      },
      "discountAmount": {
        "color": "#000000"
      }
    },
   "contents": {
    "note": true,
    },    
    "text": {
      "total": "Subtotal",
      "button": "Checkout",
      "noteDescription": "Any dietary requirements?",
    },
    "popup": false
  },

  "toggle": {
    "styles": {
      "toggle": {
        "background-color": "#0054ff",
        ":hover": {
          "background-color": "#004ce6"
        },
        ":focus": {
          "background-color": "#004ce6"
        }
      }
    }
  },
  "lineItem": {
    "styles": {
      "variantTitle": {
        "color": "#000000"
      },
      "title": {
        "color": "#000000"
      },
      "price": {
        "color": "#000000"
      },
      "fullPrice": {
        "color": "#000000"
      },
      "discount": {
        "color": "#000000"
      },
      "discountIcon": {
        "fill": "#000000"
      },
      "quantity": {
        "color": "#000000"
      },
      "quantityIncrement": {
        "color": "#000000",
        "border-color": "#000000"
      },
      "quantityDecrement": {
        "color": "#000000",
        "border-color": "#000000"
      },
      "quantityInput": {
        "color": "#000000",
        "border-color": "#000000"
      }
    }
  }
},
      });
    });
  }
})();
<\/script>`
        );
   },
  },
  mounted() {
        setTimeout(() => {
          this.checkMounted()
        }, 800); 
        window.scrollTo(0, 0)
  },  
  apollo: {
    site: {
      query: gql`
        {
         site(where: {id: "cksd1yz2watsd0b39tpabf9fj"}) {
           title
           showShop
           byline
           address
           googleMapsLink
           email
           phoneNumber
           instagramHandle
           bookingLink
           menuTitle
           menu {
             url
           }
           wineTitle
           wineList {
             url
           }
          update {
             html
           }
           backgroundImage {
             url
           } 
            backgroundColour {
              hex
            } 
            textColour {
              hex
            } 
            linkColour {
              hex
            }
            shopText{
              html
            }                                                    
        }
      }
      `
    }
  }
}
</script>

<style>

.bg-filter {
    filter: grayscale(100) contrast(1.2) brightness(2.2);
}

.img-rules img {
    mix-blend-mode: multiply;
    filter: grayscale(100) contrast(1.2) brightness(1.2);
}

* {
  isolation: isolate;
}

.p-rules a {
  border-bottom:4px solid;
}

.shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}



</style>
