import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Menu from '../views/Menu.vue'
import WineList from '../views/WineList.vue'
import Shop from '../views/Shop.vue'
import Pickup from '../views/Pickup.vue'
import Gift from '../views/Gift.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/pickup',
    name: 'Pickup',
    component: Pickup
  },  
  {
    path: '/gift',
    name: 'Gift',
    component: Gift
  },    
  {
    path: '/winelist',
    name: 'WineList',
    component: WineList
  },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
