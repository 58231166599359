<template>
  <div id="app" class="bg-white">
    <router-view> </router-view>
    
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
    
  }
}
</script>

<style>

</style>
