<template>
  <div class="home font-bold font-base text-base lg:text-vw tracking-base" :style="{'background-color': site.backgroundColour.hex}">
    <div class="p-2 flex flex-wrap relative z-30" :style="{'color': site.textColour.hex}">
      <div class="pr-4 uppercase" v-if="site.title" >
        <a :style="{'color': site.linkColour.hex}" href="/">{{site.title}}</a>
        {{site.byline}}
      </div>     
      <div class="pr-4 uppercase" v-if="site.menu" >
        <a :style="{'color': site.linkColour.hex, 'border-color': site.linkColour.hex, 'background-color': site.backgroundColour.hex}" class="mr-4 px-2 border-4 "  :href="site.menu.url">Food Menu</a>
        <span class="hidden lg:inline-block">{{site.menuTitle}}</span>
      </div> 
      <div class="pr-4 uppercase" v-if="site.wineList" >
        <a :style="{'color': site.linkColour.hex, 'border-color': site.linkColour.hex, 'background-color': site.backgroundColour.hex}" class="mr-4 px-2 border-4 mt-2 block lg:inline-block lg:mt-0"  :href="site.wineList.url">Wine List</a>
        <span class="hidden lg:inline-block">{{site.wineTitle}}</span>
      </div>            
      <div class="pr-4 uppercase" v-if="site.googleMapsLink" >
        <a :style="{'color': site.linkColour.hex}" class="pr-4" :href="site.googleMapsLink" target="_blank">Google Maps</a>
        {{site.address}}
      </div>
      <div class="pr-4 w-full overflow-ellipsis overflow-hidden uppercase" v-if="site.email" >
        <a :style="{'color': site.linkColour.hex}" class="pr-4 " :href="'mailto:' + site.email" >Email</a>
        {{site.email}}
      </div>
      <div class="pr-4 uppercase" v-if="site.phoneNumber" >
        <a :style="{'color': site.linkColour.hex}" class="pr-4" :href="'tel:' + site.phoneNumber" >Hotline</a>
        {{site.phoneNumber}}
      </div>
      <div class="pr-4 w-full overflow-ellipsis overflow-hidden uppercase" v-if="site.instagramHandle" >
        <a :style="{'color': site.linkColour.hex}" class="pr-4" :href="'https://www.instagram.com/' + site.instagramHandle" target="_blank">Instagram</a>@{{site.instagramHandle}}
      </div> 
      <div class="pr-4 w-full lg:w-auto mt-8 lg:mt-0 uppercase" v-if="site.showBooking" >
        <a :style="{'color': site.linkColour.hex, 'border-color': site.linkColour.hex, 'background-color': site.backgroundColour.hex}" class="mr-4 px-2 border-4 " :href="site.bookingLink" target="_blank">Book Now</a>
      </div> 
      <div class="w-full mt-24 mb-16 uppercase text-center text sticky top-4 z-10" v-if="site.showShop" >
        {{site.shopTitle}} <br>
        <router-link 
          to="/shop"
          :style="{ 'color': site.linkColour.hex, 
                    'border-color': site.linkColour.hex, 
                    'background-color': site.backgroundColour.hex}" 
          class="mr-4 px-2 border-4 " :href="site.shopButtonUrl">
          {{site.shopButtonText}}
        </router-link>
      </div>
      <div class="w-full mt-24 mb-16 uppercase text-center text sticky top-32 z-10" v-if="site.showGift" >
        <br>
        <router-link 
          to="/gift" 
          :style="{ 'color': site.linkColour.hex, 
                    'border-color': site.linkColour.hex, 
                    'background-color': site.backgroundColour.hex}"
          class="mr-4 px-2 border-4 " href="/gift">
          {{site.shopGiftButtonText}}
          </router-link>
      </div>      
      <div class="w-full mt-16" v-if="site.update">
        <div v-html="site.update.html" class="p-rules img-rules whitespace-pre-line"></div>
      </div>
      <div class="uppercase">
        Rebel Rebel {{new Date().getFullYear()}}  
      </div>           
    </div>
    <div v-if="site.backgroundImage" class="inset-0 fixed w-full h-full z-10  pointer-events-none bg-filter">
      <div  class="h-full w-full">
        <img  class="object-cover h-full w-full " :src="site.backgroundImage.url" alt="">
      </div>
    </div>
    <div class="inset-0 w-full h-full fixed mix-blend-hard-light z-20 pointer-events-none " :style="{'background-color': site.backgroundColour.hex}"></div>  
  </div>    
</template> 

<script>
import gql from "graphql-tag";


export default {
  name: 'Home',
  components: {

  },
  apollo: {
    site: {
      query: gql`
        {
         site(where: {id: "cksd1yz2watsd0b39tpabf9fj"}) {
           title
           showShop
           shopTitle
           shopButtonText
           shopButtonUrl
           shopGiftButtonText
           showGift
           showBooking
           byline
           address
           googleMapsLink
           email
           phoneNumber
           instagramHandle
           bookingLink
           menuTitle
           menu {
             url
           }
           wineTitle
           wineList {
             url
           }
          update {
             html
           }
           backgroundImage {
             url
           } 
            backgroundColour {
              hex
            } 
            textColour {
              hex
            } 
            linkColour {
              hex
            }                                        
        }
      }
      `
    }
  }
}
</script>

<style>

.bg-filter {
    filter: grayscale(100) contrast(1.2) brightness(2.2);
}

.img-rules img {
    mix-blend-mode: multiply;
    filter: grayscale(100) contrast(1.2) brightness(1.2);
}

.p-rules p {
  margin-bottom:1em;
}

* {
  isolation: isolate;
}

.p-rules a {
  border-bottom:4px solid;
}



</style>
